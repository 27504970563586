<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <!-- <div class="card-header">
          <h3 class="mb-0">Funcionários</h3>
        </div> -->

      <ul class="nav nav-pills col-md-12" role="tablist">
        <li
          v-for="(item, index) in menus"
          :key="index"
          class="col-md-3 col-6 col-sm-3 col-lg-3"
        >
          <a
          v-show="item.nome == 'Permissões' ? lista_permissoes_filial['c_Grupo'] ? true : false : true"
            class="card nav-link rounded flex-column align-items-between"
            data-toggle="pill"
            style=""
            @click="subpatas(item.route, item)"
          >
            <span class="nav-icon py-2 w-auto">
              <span class="svg-icon svg-icon-3x">
                <inline-svg :src="item.url" />
              </span>
            </span>
            <span class="nav-text font-size-sm font-weight-bolder text-center">
              {{ item.nome }}
              </span
            >
          </a>
        </li>
      </ul>

      <!-- show-checkbox-->
    </div>

    <div class="card col-md-12">    Rotas : {{ routers }}</div>

    <div class="col-md-12">
      <div class="full-height bg-ligth row">
        <div class="col-sm-12 col-md-5 col-lg-4 col-xs-4 col-12">
          <!-- <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6" style="word-break: break-word">
                 -->
          <!-- <div style=" overflow-x: scroll;width:100%;" > -->
          <div class="d-flex justify-content-between">
            <h1>Diretorios</h1>
            <v-btn elevation="2" color="primary" @click="meusarquivo"
              >Raiz Diretorios</v-btn
            >
          </div>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card :elevation="hover ? 24 : 6" class="pt-2">
                <v-jstree
                  style="overflow-y: auto; min-height: 100px"
                  :data="lista_pastas"
                  multiple
                  allow-batch
                  whole-row
                  @item-click="itemClick"
                ></v-jstree>
                <!--                 
                  <v-jstree :data="lista_pastas"   
                     show-checkbox multiple allow-batch whole-row  
                    >
       <template scope="_">
         <div style="display: inherit; width: 300px"    v-on:dblclick="counter += 1, funcao()" >
             <button   @item-click="itemClick">
           <i :class="_.vm.themeIconClasses" ></i>
         
        {{_.model.text}} </button>  
           <button class="ml-1" style="border: 0px; background-color: transparent; cursor: pointer;" @click="customItemClick(_.vm, _.model, $event)"><i style="font-size:10px;" class="fas fa-trash"></i></button>
         </div>
       </template>
     </v-jstree> -->
              </v-card></template
            ></v-hover
          >

          <!-- </v-card></template
          ></v-hover> -->
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 col-xs-4 col-12">
          <h1>Meus arquivos</h1>

          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                :loading="loading"
                :elevation="hover ? 24 : 6"
                class="mx-auto "
              >
                <template slot="progress">
                  <v-overlay absolute class="d-flex flex-column text-center">
                    <div>
                      <v-progress-circular size="75" indeterminate>
                        <span>Loading</span>
                      </v-progress-circular>
                    </div>
                    <div>
                      <!-- <v-btn text dark @click="loading = false" class="mt-3"
                        >Deactivate loading</v-btn
                      > -->
                    </div>
                  </v-overlay>
                </template>
                <b-table
                hover
                Small
                  :fields="['nome', 'acoes']"
                  :items="lista_pastas_doc"
                  :per-page="perPage"
                  :current-page="currentPage"
                  
                  id="empresa-table"
                  class="
                    table
                    table-vertical-center
                    table-borderless
                  "
                  show-empty
                  empty-text="Nenhum registro encontrado!"
                >
                  <template #head(acoes)><span></span></template>
                  <template #cell(nome)="{ item }">
                    <!-- <b-icon icon="file-earmark" aria-hidden="true"></b-icon> -->
                    <span  v-b-popover.hover="'This is a prod'">
                    <b-icon :icon="item.icon" aria-hidden="true"></b-icon>

                    {{ item.nome }}
                  </span>
                  </template>

                  <template #cell(acoes)="{ item }">
                    <div class="text-right w-100">
                      <button
                      v-if="item.apenasVer"

                        v-show="lista_permissoes_filial.u_Pastas"
                        @click="atualizar(item)"
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Editar registro"
                      >
                        <i class="far fa-edit text-primary"></i>
                      </button>

                      <button
                      v-if="item.apenasVer"
                        v-show="lista_permissoes_filial.lock_Pastas"
                        @click="
                          confirm(
                            item.icon == 'folder' ? 'Pasta' : 'Arquivo',
                            item
                          )
                        "
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Bloquear/Desbloquear registro"
                      >
                        <i class="fas fa-unlock-alt text-danger"></i>
                      </button>
                      <button
                        v-show="lista_permissoes_filial.ra_Pastas"
                        @click="
                          itemSubsClick(
                            item,
                            item.icon == 'folder' ? 'folder' : 'file'
                          )
                        "
                        class="btn btn-icon btn-light btn-sm mx-1"
                        v-b-tooltip.hover
                        title="Entrar"
                      >
                        <b-icon
                          icon="arrow-up-square"
                          scale="2"
                          variant="success"
                        ></b-icon>
                      </button>
                    </div>
                  </template>
                </b-table>
<div class="row">
                <b-pagination
                pills
                 class="col-md-12"
                  v-model="currentPage"
                  :total-rows="lista_pastas_doc.length"
                  :per-page="perPage"
                  aria-controls="empresa-table"
                  first-text="⏮"
      prev-text="⏪"
      next-text="⏩"
      last-text="⏭"
                >
                
                </b-pagination>

            <i class="ml-3"> sub-pastas:{{ lista_itens[0].folder }}</i>
              <i class="ml-1"> | </i>
            <i class="ml-1"> arquivos:{{ lista_itens[1].arquivos }}</i>

              </div>
                
              </v-card></template
          ></v-hover>
        </div>

        <!-- <div class="col-md-3 mt-2 card">
          <v-jstree
            :data="lista_pastas"
            multiple
            allow-batch
            whole-row
          ></v-jstree>
        </div> -->
        <div class="col-sm-12 col-md-3 col-lg-4 col-xs-4 col-12">
          <h1>Uploads fresh</h1>

          <Upload
            :dialog.sync="uploadDialog"
            :multiple="true"
            @filesUploaded="processUpload($event)"
          />
            <div class="row justify-content-end">
              <div class=" col-sm-5 col-md-6 col-lg-3 col-xs-3 col-3">
          <v-checkbox
              v-model="check_ocr"
              label="OCR"
              color="success"
              hide-details
            ></v-checkbox>
          </div>
          </div>

        </div>
      </div>
    </div>

    <v-dialog v-model="modal_move_folder_file" max-width="600">
      <template>
        <v-card>
          <v-card-title>Mover</v-card-title>
          <v-divider></v-divider>
        
            <div class="form-group row">
              <div class="col-md-12">
                <!-- <label class="col-md-12">Funções:*</label>; -->
                <h1>Diretorios</h1>
                <v-hover>
                  <template v-slot:default="{ hover }">
                    <v-card :elevation="hover ? 24 : 6" class="pt-2">
                      <v-jstree
                        style="overflow-y: auto; min-height: 100px"
                        :data="lista_pastas"
                        multiple
                        allow-batch
                        whole-row
                        @item-click="itemClick2"
                      ></v-jstree> </v-card></template
                >
                       <div class="row">
                <div class="col-md-6">
                  <label>
                    Pasta Escolhida:
                    <b-icon icon="folder" aria-hidden="true"></b-icon>
                    {{ id_pastas_mover.text }}</label
                  >
                </div>
                <div class="col-md-6 justify-content-end">
                  <v-btn elevation="2" color="primary" @click="mover()">
                    Mover</v-btn
                  >
                </div>
              </div>
                </v-hover>
              </div>
       
            </div>
      
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import VJstree from "vue-jstree";
import Upload from "@/view/components/arquivex/upload.vue";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  name: "Layout",

  components: {
    VJstree,
    Upload,
  },

  data: function () {
    return {
      check_ocr:true,
      lista_pastas: this.$store.state.pastas.lista_pastas,
      id_pastas_mover: { text: "", id: null },
      modal_move_folder_file: false,
      loading: false,
      uploadDialog: true,
      routers: this.$store.state.pastas.lista_pastas_doc.length >0 ? this.$store.state.pastas.lista_pastas_doc[0].caminho :"",
      subpastas_id: this.$store.state.pastas.lista_pastas_doc.length >0 ? this.$store.state.pastas.lista_pastas_doc[0].subpastas_id :0 ,
      currentPage: 1,
      perPage: 10,
      achou: "",
      pasta_select: {},
      counter: 0,
      escolhido: "",
      menus: [
        {
          route: "createPastas",
          nome: "Pastas",
          url: "media/svg/icons/Files/Folder-plus.svg",
        },
        {
          nome: "Ocr Automatico",
          url: "media/svg/icons/Design/Iconocr.svg",
        },
        {
          route: "createDocumento",
          nome: "Documentos",
          url: "media/svg/icons/Files/File-plus.svg",
        },
        {
          permissions:"c_Permission",
          route: "createPermissoes",
          nome: "Permissões",
          url: "media/svg/icons/Home/Library.svg",
        },
      ],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Arquivex" }]);
  },
  computed: {
    mensagem_alert() {
      return this.$store.state.pastas.mensagem_alert;
    },
    new_pasta() {
      return this.$store.state.pastas.new_pasta;
    },
    at_pasta(){
      return this.$store.state.pastas.at_pasta;

    },
    lista_pastas2() {
      let folders = Object.values(this.$store.state.pastas.lista_pastas_doc);
      var data = [];

      for (let i = 0; i < folders.length; i++) {
        if (folders[i].icon == "folder") {
          data.push({ id: folders[i].id, label: folders[i].nome });
        }
      }
      return data;
    },
    lista_itens() {
      let folders = Object.values(this.$store.state.pastas.lista_pastas_doc);
      var data = [{folder:0},{arquivos:0}];

      for (let i = 0; i < folders.length; i++) {
        if (folders[i].icon == "folder") {
        data[0].folder =data[0].folder  +1
        }else{
        data[1].arquivos =data[1].arquivos  +1

        }
      }
      return data;
    },

    lista_pastas_doc() {
      return this.$store.state.pastas.lista_pastas_doc;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
  },
  created() {
    this.listar_pastas();
  },

  methods: {
    async meusarquivo() {
      // await this.$store.dispatch("pastas/listar_pastas");
      // this.$store.dispatch("configEmpresa/MudarPreloader", true);
          await this.$store.dispatch("pastas/set_pasta", {});

      await this.$store.dispatch("pastas/listar_pastas_doc", 0);
          await this.$store.dispatch("pastas/listar_pastas");
          

      this.lista_pastas = this.$store.state.pastas.lista_pastas;

       

    },
    funcao: function () {
      alert("Sou uma funcao");
    },
    async listar_pastas() {
      if (!this.lista_pastas[0]) {
            await this.$store.dispatch("pastas/listar_pastas");
        await this.$store
          .dispatch("pastas/listar_pastas_doc", 0)
          .finally(() => {
                    this.$store.dispatch("configEmpresa/atualizar", "");
          });

        this.lista_pastas = this.$store.state.pastas.lista_pastas;
      } else {
        this.lista_pastas = this.$store.state.pastas.lista_pastas;

        // if(this.new_pasta.id){
        this.lista_pastas.forEach((eit) => {
          if (eit.id == this.new_pasta.subpastas_id) {
            eit.children.push({
              id: this.new_pasta.id,
              opened: false,
              selected: false,
              sequencia: this.new_pasta.sequencia,
              subpastas_id: this.subpastas_id,
              text: this.new_pasta.nome,
            });
          } else if (eit.children) {
            this.search(eit);
          }
        });
      }
      // }

      // }else{
      //   this.lista_pastas.forEach((value, index) => {

      //   })
      // }
      //  this.$store.dispatch("configEmpresa/atualizar", "");

      //  this.lista_pastas = this.$store.state.pastas.lista_pastas;
    },

    search(dev) {
      dev.children.forEach((itch) => {
        if (itch.id == this.new_pasta.subpastas_id) {
          itch.children.push({
            id: this.new_pasta.id,
            opened: false,
            selected: false,
            sequencia: this.new_pasta.sequencia,
            subpastas_id: this.new_pasta.subpastas_id,
            text: this.new_pasta.nome,
          });
        } else {
          if (itch.children) {
            this.search(itch);
          }
        }
      });
    },
    async atualizar(value) {
      switch (value.icon) {
        case "folder":
          this.$store.dispatch("configEmpresa/atualizar", value);
          this.$router.push({
            name: "createPastas",
          });

          break;
        case "file-earmark":
          this.$store.dispatch("configEmpresa/atualizar", value);
          this.$router.push({
            name: "createDocumento",
          });
          break;
      }
    },
    trocar(route) {
      // if(route ==)
      this.$router.push({ name: route });
    },
    subpatas(route, value) {
      // console.log(value.nome);
      if (value.nome == "Mover") {
        // this.showModalMoverFolderFile();
      } else {
        let a = {
          rota: this.routers,
          pastas_id: this.subpastas_id,
        };

        window.localStorage.setItem('routers', JSON.stringify(a));

        // this.$store.dispatch("documentos/set_rota", a);
        this.$store.dispatch("pastas/set_lista_pastas", this.lista_pastas);
        this.$router.push({
          name: route,
          params: { pastas_id: this.subpastas_id },
        });
      }
    },

    async itemSubsClick(data, tipo) {
      // this.routers =
      switch (tipo) {
        case "folder":
          this.subpastas_id = data.id;
          this.routers = this.routers + "\\" + data.nome;

          this.loading = true;
          await this.$store.dispatch("pastas/listar_pastas_doc", data.id);
          await this.$store.dispatch("pastas/set_pasta", data);

          this.loading = false;
          let a = {
            rota: this.routers,
            pastas_id: this.subpastas_id,
          };
          // this.$store.dispatch("documentos/set_rota", a);
          window.localStorage.setItem('routers', JSON.stringify(a));

          break;
        case "file":
          this.$router.push({
            name: "viewfile",
            params: { arquivo_id: data.id },
          });
          break;
      }
    },

    customItemClickWithCtrl(node) {
      console.log(node);
    },

    customItemClick(node) {
      console.log(node);
    },
    async itemClick(node) {
      this.loading = true;
      this.subpastas_id = node.model.id;
      this.routers = "";
      this.pasta_select = node.model;
      await this.$store.dispatch("pastas/listar_pastas_doc", node.model.id);
      await this.$store.dispatch("pastas/set_pasta", node.model);
      this.loading = false;

      this.funtion(node.model);

      //   this.routers = this.routers + node.model.text;
    },
    itemClick2(node) {
      this.loading = true;
      this.loading = false;
      this.id_pastas_mover = node.model;
    },
    funtion(model) {
      this.achou = true;
      if (this.routers != "") {
        this.routers = this.routers + "\\" + model.text;
      } else {
        this.lista_pastas.forEach((value, index) => {
          //  console.log(this.routers);
          if (this.achou) {
            this.routers = "";
            // this.routers = value.text;
            this.functionrecursivo(value, model);
          }
        });
      }

      // this.routers = this.routers + "\\" + textt;
    },

    functionrecursivo(sub, model) {
      let textt = "";
      if (this.routers != "") {
        textt = this.routers + "\\" + sub.text;
      } else {
        textt = sub.text;
      }

      if (sub.id != model.id) {
        // this.routers = textt;

        if (sub.children) {
          sub.children.forEach((value, index) => {
            if (this.achou) {
              if (value.id == model.id) {
                this.achou = false;
                textt = textt + "\\" + value.text;
                this.routers = textt;
              } else {
                console.log(value.text);
                // textt = this.routers + "\\" + value.text;
                this.routers = textt;

                this.functionrecursivo(value, model);
              }
            }
          });
        }
      } else {
        this.achou = false;
        this.routers = textt;
      }
      // if (sub.id == model.id) {
      // this.routers = textt;

      // this.routers = textt;
      // console.log(  this.routers);
      // }
    },
    async processUpload(data) {
      let formData ;
      for (var i = 0; i < data.length; i++) {
       formData = new FormData();
        let file = data[i];
        console.log(file);
        formData.append("file[" + i + "]", file);
        formData.append("rota", this.routers);
      formData.append("pastas_id", this.subpastas_id);
      formData.append("ocr", this.check_ocr);

      await this.$store.dispatch("pastas/create_documento", formData);
      }
   


      // this.loading = true;
    
     

      // this.$store.dispatch("pastas/listar_pastas_doc", this.subpastas_id);
      // this.loading = false;
    },

    async confirm(tipo, value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação deleta uma ` + tipo + ` no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.delete(tipo, value);
          }
        },
      });
    },
    async delete(tipo, value) {
      switch (tipo) {
        case "Pasta":
          await this.$store.dispatch("pastas/delete_pastas", value);

          this.fireAlert(this.mensagem_alert);

          break;
        case "Arquivo":
          await this.$store.dispatch("pastas/delete_documento", value);
          this.fireAlert(this.mensagem_alert);

          break;
      }

      // this.$store.dispatch("pastas/listar_pastas");
      // this.$store.dispatch("pastas/listar_pastas_doc", 0);
    },
    showModalMoverFolderFile() {
      this.modal_move_folder_file = true;
    },
    hideModalMoverFolderFile() {
      this.modal_move_folder_file = false;
    },
    mover() {
      let form = {
        id: this.pasta_select.id,
        nome: this.pasta_select.text,
        subpastas_id: this.id_pastas_mover.id,
      };
      this.$store.dispatch("pastas/update_pastas", form);
      this.listar_pastas();
    },
  },
};
</script>

<style scoped>
</style>
